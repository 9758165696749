
.results-table-outer {
	height: 11in;
	width: 8.5in;
	margin: 50px;
    .resultsTable {
        tr {
            th {
                background-color: silver;
                padding: 5px;
                font-size: 12px;
            }
            td {
                padding: 5px;
                &.subheader {
                    background-color: black!important;
                    color: white;
                    font-size: 12px;
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }
        }
        &.eo tr:nth-child(even) {
            background-color: #D8E6F9;
        }
    }
    .pcentries {
        margin: 10px 0;
        ul {
            list-style: decimal;
        }
    }
}
