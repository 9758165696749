@import 'style/_base';

.mfaCode {
    display:flex;
    flex-flow: row nowrap;
    margin: auto;
    input[type=text] {
        width:20px!important;
        height: 30px;
        text-align:center;
        font-size:24px;
        &:hover {
            height: 24px;
        }
    }
    .dash {
        font-size: 30px;
    }
}