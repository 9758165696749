@import './_base';

.progress-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        &::before {
            position: absolute;
            content: "";
            border-bottom: 2px solid $MIDGRAY;
            width: 100%;
            top: 12px;
            left: -50%;
            z-index: 2;
        }
        &::after {
            position: absolute;
            content: "";
            border-bottom: 2px solid $MIDGRAY;
            width: 100%;
            top: 12px;
            left: 50%;
            z-index: 2;
        }
        .step-icon {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: white;
            font-size: 24px;
            margin-bottom: 6px;
            color:$MIDGRAY;
            &.complete {
                color: $CORAL1;
            }
        }
        .step-label {
            font-size: 16px;
        }
        &.active {
            .step-icon {
                color: $CORAL1;
                border: none;
            }
            .step-label {
                font-weight: 600;
            }
        }
        &:first-child::before {
            content: none;
        }
        &:last-child::after {
            content: none;
        }
    }
}
@media (max-width:768px) {
    .progress-indicator {
        .step {
            .step-label {
                text-align: center;
            }
        }
    }
}