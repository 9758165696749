@import 'style/_base';

.avatar-container {
    width:100px;
    height:100px;
    border:1px solid $DARKGRAY;
}

.avatar-button-container {
    display: flex;
    //justify-content: center;
    button.linkButton {
        margin: 0!important;
        padding: 4px 0;
    }
}