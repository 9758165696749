@import 'style/2023Base';

.setupSlide {
	float:left;
	width:750px;
	min-height:400px;
	background-color: white;
    border: 1px solid $MIDGRAY;
    border-radius: 15px;
    padding-bottom: 10px;
    box-shadow: 0px 2px 8px 0px $DARKGRAY;
	.title {
		margin:10px 10px 50px;
		text-align:center;
		font-size:26px;
	}
	.body {
		width:650px;
		margin:auto;
        .setupSlideBody {
            display: flex;
            flex-flow: column nowrap;
            input {
                font-size: 18px;
                display: flex;
                flex: 1;
                width: 600px;
            }
            li.row {
                display: flex;
                input {
                    margin: 0 2px;
                }
            }
        }
	}
}
