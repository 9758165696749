@import 'style/_base';

.channelSearch {
	display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
	.topSection {
        display: flex;
        flex-flow: row nowrap;
        
		border-bottom: 1px solid $DARKGRAY;
		padding: 10px;
		span {
			font-size: 22px;
		}
	}
	.centerSection {
		background-color: $LIGHTGRAY;
		overflow-x: auto;
		.searchStats {
			margin: 10px;
		}
		.searchResultList {
			width: unset;
			.channelSearchResult {
				border: 1px solid $DARKGRAY;
				border-radius: 4px;
				background-color: white;
				margin: 10px;
				float: left;
				clear: left;
				width: 95%;
				white-space: nowrap;
				.resultContainer {
					float: left;
					margin: 10px;
					.avatar {
						color: white;
						text-align:center;
						margin-top: 5px;
						width: 40px;
						height: 40px;
						border-radius: 5px;
						background-color: $MIDGRAY;
						float: left;
						.char {
							padding-top:10px;
						}
						img {
							width: 40px;
							height: 40px;
							border-radius: 5px;
						}
					}
					.resultDetails {
						display: inline-block;
						white-space:normal;
						margin-right: 40px;
						.messageChannel {
							font-size: 12px;
							float: left;
							margin-left:5px;
						}
						.messageTime {
							font-size: 12px;
							color: $MIDGRAY;
							float: left;
							margin-left:20px;
						}
						.messageText, .mediaName {
							padding: 5px;
							float: left;
							clear: left;
							display: inline-block;
						}
						.messageMedia {
							float: left;
							clear: left;
							padding-left: 5px;
						}
						.mediaName {
							font-size: 12px; 
						}
					}
				}
			}
		}
	}
}