@import './print.scss';

.mailing-labels-page {
	background-color: gray;
	margin: 0;
	padding: 0;


	background-color: white;
	height: 11in;
	width: 8.5in;
	margin-bottom: 5px;
	.mailing-labels-inner {
		padding: .5in .1875in;
        height: 10in;
		.mailing-label {
			float: left;
			height: 1in;
			width: 2.625in;
			margin-right: .125in;
			margin-bottom: 0;
			&:nth-child(3n+0) {
				margin-right:0;
			}
			.content {
				font-size: 12px;
				margin: 5px 15px;
			}
		}
    }
}