@import 'style/_base';

.file-actions {
    border-top: 1px solid $DARKGRAY;
    padding: 10px 0;
    margin: 5px 0 0 30px;
}

table.files-container {
    border: 1px solid $DARKGRAY;
    margin: 0 30px 30px 30px;
    thead {
        background-color: $MIDGRAY;
        tr th { 
            border-bottom: 1px solid $DARKGRAY;
            border-left: 1px solid $DARKGRAY;
            text-align: left;
            padding: 8px 12px;
            font-weight: 600;
            &:first-child {
                border-left: none;
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid $DARKGRAY;
            &.file-item {
                cursor: pointer;
                font-size: 18px;
                &.selected {
                    font-weight: bold;
                }
                td {
                    border-left: 1px solid $DARKGRAY;
                    padding: 8px 12px;
                    cursor: pointer;
                    &:first-child {
                        border-left: none;
                    }
                    &.file-item-left {
                        display: flex;
                        flex-flow: row wrap;
                        align-content: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .file-icon {
                        margin: 0 10px;
                    }
                    .file-name {
                        margin: 0 10px;
                    }
                    .file-item-right {
                        display: flex;
                        margin-left: 10px;
                    }
                }
                &:hover {
                    background-color: $BLUE3;
                }
            }
        }
    }
}