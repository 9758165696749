.dashCard {
    background-color: white;
    width: 1082px;
    height: 812px;
    .field {
        font-size: 48px;
        position: absolute;
        &.year {
            left: 170px;
            top: 180px;
        }
        &.className {
            left: 730px;
            top: 165px;
            font-size: 62px;
            font-weight: bold;
        }
        &.make {
            left: 180px;
            top: 265px;
        }
        &.model {
            left: 665px;
            top: 265px;
        }
        &.owner {
            left: 200px;
            top: 360px;
        }
        &.location {
            left: 240px;
            top: 480px;
            font-size: 24px;
        }
        &.qrcode {
            left: 800px;
            top: 645px;
        }
    }
}
