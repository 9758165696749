@import 'style/_base';


.middletwin-editor {
    display: flex;
    flex-flow: column nowrap;
    width: 360px;
    margin: 0 auto;
    .editor-toolbar {
        display: flex;
        flex-flow: row nowrap;
        height: 30px;
        align-items: center;
        button {
            background-color: $WHITE;
            padding: 2px;
            width: 20px;
            height: 20px;
            color: $DARKGRAY;
            border-radius: 0;
            border:1px solid $DARKGRAY;
            margin: 2px;
            &.selected {
                background-color: $MIDGRAY;
            }
            &:hover {
                background-color: $DARKGRAY;
                color: $WHITE;
            }
        }
    }
    .editable-div-outer {
        display: flex;
        flex-flow: column nowrap;
        height: 170px;
        overflow: auto;
        border: 1px solid $MIDGRAY;
        .editable-div {
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            padding: 7px;
            background-color: $WHITE;
            outline: none;
        }
    }
}