
.ticketOuter {
	background-color: white;
	height: 11in;
	width: 8.5in;
	margin-bottom: 5px;
    .ticket {
        width: 7.4in;
        margin: .5in;
        border: 5px solid #A6A6A6;
        position: absolute;
        height: 3.5in;
        float: left;
        .qrcode {
            float: right;
            margin-top: 1in;
            margin-right: 8px;
        }
        .detail {
            border-bottom: 5px solid #A6A6A6;
            border-right: 5px solid #A6A6A6;
            float: left;
            text-align: center;
            font-size: 24px;
            padding: 6px;
            width: 6in;
            height: .6in;
        }
        .info {
            float: left;
            font-size: 12px;
            text-align: center;
            width: 100%;
            margin-top: 13px;
        }
    }
}