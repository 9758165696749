@import './2023Base.scss';

.newPageSelector {
    ul {
        list-style: none;
        display: flex;
        flex-flow: row;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            margin: 10px;
            padding: 4px;
            cursor: pointer;
            .newPage {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                border: 2px solid $CORAL1;
                border-radius: 10px;
                padding: 4px;
                min-width: 100px;
                .newPageIcon {
                    font-size: 64px;

                }
                .newPageLabel {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
}