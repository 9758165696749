@media print {
    @page {
        margin: 0;
    }
    body {
        -webkit-print-color-adjust: exact; /* Chrome, Safari */
        color-adjust: exact; /* Firefox */
    }
    body, html {
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
    }
    * {
        box-sizing: border-box;
    }
    .landscape-printable-area {
        position: fixed!important;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }
    .portrait-printable-area {
        position: relative;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        height: auto;
    }

    .landscape {
        @page {
            size: landscape;
        }
        width: 11in;
        height: 8.5in;
    }
    
    .portrait {
        @page {
            size: portrait;
            margin: 0.5in;
        }
        width: 8.5n;
        height: 11in;
    }

    .hide-data {
        .registration-box, .qrcode-box {
            visibility: hidden;
        }
    }
    .mailing-labels-page {
        page-break-after: always;
        .mailing-labels-inner {
            display: flex;
            flex-wrap: wrap;
            page-break-inside: avoid;
        }

        .mailing-label {
            width: calc(100% / 3); /* Assuming 3 labels per row */
            box-sizing: border-box;
            padding: 10px;
        }

        .page-break {
            page-break-before: always;
            display: block;
            height: 0;
            line-height: 0;
        }
    }

    .ballotsOuter, .results-table-outer, 
    .invoice-outer,
    .memberCardsOuter, .ticketOuter  {
        page-break-after: always;
    }
}