@import 'style/2023Base';

.flow-details-outer {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    border: 1px solid $DARKBORDER;
    .flow-details-templates {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        border-bottom: 1px solid $DARKGRAY;
        justify-content: center;
        align-items: center;
        .no-templates-message {
            font-size: 28px;
            font-weight: 700;
            color: $DARKGRAY;
        }
        .flow-diagram {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .step {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                text-align: center;
                margin: 0 10px;
                &::before {
                    position: absolute;
                    content: "";
                    border-bottom: 2px solid $MIDGRAY;
                    width: 100%;
                    top: 12px;
                    left: -50%;
                    z-index: 2;
                }
                &::after {
                    position: absolute;
                    content: "";
                    border-bottom: 2px solid $MIDGRAY;
                    width: 100%;
                    top: 12px;
                    left: 50%;
                    z-index: 2;
                }
                .step-icon {
                    position: relative;
                    z-index: 5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: white;
                    font-size: 24px;
                    margin-bottom: 6px;
                }
                .step-label {
                    font-size: 16px;
                    font-weight: 600;
                    max-width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .step-actions {
                    display: flex;
                    flex: 1;
                    flex-flow: row nowrap;
                    flex-grow: 2;
                    width: 100%;
                    justify-content: center;
                    .step-action {
                        cursor: pointer;
                        color: $DARKGRAY;
                        margin: 0 5px;
                        &:hover {
                            color: $LINK;
                        }
                    }
                }
                &.active {
                    .step-icon {
                        color: blue;
                    }
                    .step-label {
                        font-weight: 600;
                    }
                }
                &:first-child::before {
                    content: none;
                }
                &:last-child::after {
                    content: none;
                }
            }
        }
    }
    .flow-details-members {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        .no-members-message {
            font-size: 28px;
            font-weight: 700;
            color: $DARKGRAY;
            text-align: center;
        }
    }
}