@import 'style/base';

.print-container {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    overflow: auto;
    border: 1px solid $MIDGRAY;
    flex: 5 1;
    background-color: $WHITE;
    height: 11in;
        width: 8.5in;
        margin: 50px;
    .page-editor {
        display: flex;
        flex-flow: column nowrap;
        flex: 5 1;
        justify-content: flex-start;
        &.mobile {
            .page-section {
                flex-flow: column nowrap;
                border-bottom: 0;
                .page-element {
                    border-right: 0;
                    border-bottom: 1px solid $MIDGRAY;
                }
            }
        }
        .empty-message {
            justify-self: center;
            align-self: center;
            flex: 1;
            text-align: center;
            font-size: 28px;
            font-weight: 600;
            color: $MIDGRAY;
        }
        .page-section {
            display: flex;
            flex-flow: row nowrap;
            flex: 1;
            border-bottom: 1px solid $MIDGRAY;
            //margin: 1px;
            position: relative;
            
            .empty-message {
                justify-self: center;
                align-self: center;
                flex: 1;
                text-align: center;
                font-size: 28px;
                font-weight: 600;
                color: $MIDGRAY;
            }
            .page-element {
                display: grid;
                flex: 1;
                border-right: 1px solid $MIDGRAY;
                align-items: center;
                justify-content: center;
                img {
                    justify-self: center;
                }
                .element-text {
                    padding: 10px;
                }
            }
        }
    }
}
