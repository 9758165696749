@import 'style/2023Base';

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $DARKGRAY;
    z-index: 100;
    .modalContainerOuter {
        display: flex;
        background-color: $WHITE;
        border-radius: 10px;
        justify-content: center;
        width: fit-content;
        margin: 100px auto;
        .modalContainerInner {
            display: flex;
            flex-flow: column nowrap;
            margin: 20px;
            .modalCloser {
                display: flex;
                justify-content: flex-end;
                font-size: 24px;
                svg {cursor: pointer;}
            }
            .modalContainerTitle {
                h4 {
                    margin: 0 0 20px;
                }
            }
            .modalContent {

            }
            .modalButtons {
                .buttonsHolder {
                    button {
                        margin: 0 4px;
                    }
                }
            }
        }
    }
}