@import './base';

.browser-container {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    overflow: auto;
    border: 1px solid $MIDGRAY;
    flex: 5 1;
    height: 100%;
    background-color: $WHITE;
    margin: 0 auto;
    width: 100%;
    &.mobile {
        width: 500px;
    }
    &.printer {
        height: 11in;
        width: 8.5in;
        margin: 50px;
        &.landscape {
            height: 8.5in;
            width: 11in;
        }
    }
    .page-editor {
        display: flex;
        flex-flow: column nowrap;
        flex: 5 1;
        justify-content: flex-start;
        &.mobile {
            overflow: auto;
            .page-section {
                flex-flow: column nowrap;
                border-bottom: 0;
                min-height: 700px;
                .page-element {
                    border-right: 0;
                    border-bottom: 1px solid $MIDGRAY;
                }
            }
        }
        .empty-message {
            justify-self: center;
            align-self: center;
            flex: 1;
            text-align: center;
            font-size: 28px;
            font-weight: 600;
            color: $MIDGRAY;
        }
        .page-section {
            display: flex;
            flex-flow: row nowrap;
            flex: 1;
            border-bottom: 1px solid $MIDGRAY;
            position: relative;
            .section-toolbox {
                display: none;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                right: 0;
                top: 0;
                background-color: $MIDGRAY;
                cursor: pointer;
            }
            &:hover {
                .section-toolbox {
                    display: flex;
                }
            }
            &.selected {
                outline-style: dashed;
                outline-width: 2px;
                outline-color: #7FADDC;
            }
            .empty-message {
                justify-self: center;
                align-self: center;
                flex: 1;
                text-align: center;
                font-size: 28px;
                font-weight: 600;
                color: $MIDGRAY;
            }
            .page-element {
                display: grid;
                flex: 1;
                border-right: 1px solid $MIDGRAY;
                align-items: center;
                justify-content: center;
                .element-image {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: center;
                    background-size: cover;
                }
                .element-text {
                    padding: 10px;
                }
                &.selected {
                    outline-style: dashed;
                    outline-width: 2px;
                    outline-color: #7FADDC;
                }
                .element-toolbox {
                    display: none;
                    flex-flow: row nowrap;
                    align-self: flex-start;
                    justify-self: center;
                    justify-content: space-between;
                    min-width: 100px;
                    height: 30px;
                    border: 1px solid $MIDGRAY;
                    border-top: none;
                    border-radius: 0 0 10px 10px;
                    position: fixed;
                    background-color: $DARKGRAY;
                    color: $WHITE;
                    .element-title {
                        display: flex;
                        margin: 4px;
                    }
                    .toolbox-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $MIDGRAY;
                        color: $DARKGRAY;
                        width: 22px;
                        height: 22px;
                        border-radius: 14px;
                        font-size: 14px;
                        margin: 2px;
                        padding: 2px;
                        cursor: pointer;
                        &:hover {
                            background-color: $DARKGRAY;
                            color: white;
                        }
                    }
                }
                &:hover {
                    .element-toolbox {
                        display: flex;
                    }       
                }
                
            }
        }
        &.printer {
            position: relative;
            .page-section {
                border: none;
                width: 8.5in;
                .page-element {
                    inset: 0;
                }
            }
            &.landscape {
                .page-section {
                    width: 11in;
                }
            }
        }
        .section-editor {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: calc(50% - 200px);
            width: 400px;
            height: 100px;
            background-color: white;
            border-top: 1px solid $MIDGRAY;
            border-left: 1px solid $MIDGRAY;
            border-right: 1px solid $MIDGRAY;
            border-radius: 10px 10px 0 0;
            .section-editor-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $MIDGRAY;
                height: 40px;
                border-radius: 30px;
                cursor: pointer;
                margin: 0 4px;
                padding: 0 15px;
                &:hover {
                    background-color: $DARKGRAY;
                    color: white;
                }
                &.disabled {
                    background-color: $LIGHTGRAY;
                    color: $MIDGRAY;
                }
            }
        }
        .element-editor {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: calc(50% - 200px);
            width: 400px;
            padding: 10px;
            background-color: $DARKGRAY;
            border-top: 1px solid $MIDGRAY;
            border-left: 1px solid $MIDGRAY;
            border-right: 1px solid $MIDGRAY;
            border-radius: 10px 10px 0 0;
            label {
                color: white;
            }
            .element-settings {
                display: flex;
                flex-flow: row nowrap;
                //height: 40px;
                .image-editor {
                    display: flex;
                    flex-flow: column;
                    margin: 10px 0;
                    height: 200px;
                }
                .text-editor {
                    width: 400px;
                    height: 240px;
                    .ql-container {
                        border: 1px solid $MIDGRAY;
                    }
                }
                .editor-row {
                    display: flex;
                    justify-content: space-around;
                    .editor-group {
                        display: flex;
                        flex-flow: column;
                    }
                }
                .image-canvas {
                    visibility: hidden;
                    display: none;
                }
            }
            .editor-buttons {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                height: 60px;
                border-top: 1px solid $MIDGRAY;
                .section-editor-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $MIDGRAY;
                    height: 40px;
                    border-radius: 30px;
                    cursor: pointer;
                    margin: 0 4px;
                    padding: 0 15px;
                    &:hover {
                        background-color: $DARKGRAY;
                        color: white;
                    }
                    &.disabled {
                        background-color: $LIGHTGRAY;
                        color: $MIDGRAY;
                    }
                }
            }
        }
    }
}

.page-details {
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 75px;
    background-color: $DARKBACKGROUND;
    color: $DARKTEXT;
    .page-editor-settings {
        margin-top: 0px;
        display: flex;
        flex-flow: column nowrap;
        width: 25%;
        min-width: 400px;
        overflow: auto;
        label {
            color: $DARKTEXT;
        }
        .toggle {
            color: $DARKTEXT;
        }
        .page-settings {
            display: flex;
            flex-flow: column nowrap;
            padding: 15px;
            .page-name {
                font-size: 24px;
                justify-content:center;
                display:flex;
            }
            .button-container {
                justify-content:center;
                height: 40px;
                display:flex;
            }
        }
        .page-sections {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            border-top: 1px solid $DARKBORDER;
            margin: 0 20px;
            .section-block {
                display: flex;
                flex-flow: column nowrap;
                summary {
                    background-color: $DARKBLOCK;
                    border: 1px solid $DARKBORDER;
                    border-radius: 5px;
                    margin: 5px 10px;
                    padding: 5px 10px;
                    justify-content: center;
                }
                .modules {
                    display: flex;
                    flex-flow: column wrap;
                    margin-left: 20px;
                    .module-block {
                        display: flex;
                        flex-flow: column nowrap;
                        summary {
                            border: 1px solid $DARKBORDER;
                            border-radius: 5px;
                            padding: 5px 10px;
                        }
                        .module-settings {
                            padding: 5px 10px;
                            .module-settings-tabs {
                                display: flex;
                                flex-flow: row nowrap;
                                justify-content: space-around;
                                .module-settings-tab {
                                    display: flex;
                                    flex-flow: column;
                                    flex: 1;
                                    border: 1px solid $DARKBORDER;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 10px 0;
                                    cursor: pointer;
                                    .module-settings-tab-label {
                                        margin-top: 4px;
                                    }
                                    &.selected {
                                        color: $BLUE1;
                                        cursor: unset;
                                    }
                                }
                            }
                            .module-settings-container {
                                display: flex;
                                flex-flow: column nowrap;
                                flex: 1;
                                min-height: 100px;
                                border: 1px solid $DARKBORDER;
                                padding: 5px;
                                h5 {
                                    margin: 0;
                                    padding: 0;
                                    border-bottom: 1px solid $DARKBORDER;
                                    font-weight: 500;
                                    background-color: $DARKBLOCK;
                                }
                                .middletwin-editor {
                                    width: unset;
                                }
                                .module-setting {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    margin: 2px 4px 4px;
                                    &.centered {
                                        justify-content: center;
                                    }
                                    .button-setting-buttons {
                                        display: flex;
                                        flex-flow: row nowrap;
                                        background-color: $DARKBLOCK;
                                        border: 1px solid $DARKBORDER;
                                        border-radius: 5px;
                                        .setting-button {
                                            display: flex;
                                            justify-content: center;
                                            border-right: 1px solid $DARKBORDER;
                                            margin: 4px 6px;
                                            cursor: pointer;
                                            &:last-child {
                                                border-right: none;
                                            }
                                            &.selected {
                                                color: $BLUE1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    button {
                        margin: 5px 10px;
                    }
                }
                summary {
                    display: flex;
                    flex-flow: row nowrap;
                    flex: 1;
                    justify-content: space-between;
                    align-items: center;
                    .summary-left {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        .summary-title {
                            padding-left: 6px;
                            text-transform: capitalize;
                        }
                    }
                    .summary-right {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: center;
                        button {
                            background-color: unset;
                            border: none;
                            color: $DARKTEXT;
                            border-radius: 0;
                            margin: 0;
                            padding: 3px 6px;
                        }
                    }
                }
            }
            button {
                margin: 5px 10px;
            }
        }
    }
    .page-editor-view {
        display: flex;
        flex-flow: column nowrap;
        width: 50%;
        background-color: $WHITE;
        border-left: 1px solid $LIGHTGRAY;
        border-right: 1px solid $LIGHTGRAY;
        padding: 15px;
        overflow: auto;
    }
}