@import './_base';

.details-container {
    width: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 250;
    overflow: auto;
    .details-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-size: 32px;
        border-bottom: 1px solid $BLUE3;
        padding: 15px;
        background-color: $BLUE1;
        color: $BLACK;
        .closer {
            cursor: pointer;
        }
    }
    .details-contents {
        display: block;
        overflow: auto;
        position: relative;
        min-height: auto;
        flex: 1 1 100%;
    }
}