@import 'style/_base';

.longform_outer {
    display: flex;
    flex-flow: row nowrap;
    .longform_section_links_outer {
        display: flex;
        flex-flow: column nowrap;
        width: 30%;
        position: fixed;
        bottom: 0;
        top: 75px;
        overflow: auto;
        .longform_section_links_inner {
            padding:20px 0 0 20px;
            border-right: 1px solid $DARKGRAY;
            flex-grow: 1;
            h3 {
                margin: 0;
                padding: 10px 0;
                a {
                    text-decoration:none;
                    color:unset;
                    font-weight: normal;
                    &:hover {
                        font-weight: bold;
                    }
                }
                &.highlight {
                    a {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .longform_sections_outer {
        display: flex;
        flex-flow: column nowrap;
        width: 70%;
        position: fixed;
        bottom: 0;
        right: 0;
        top: 75px;
        overflow: auto;
        .longform_sections_inner {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 40px 40px 20px;
            margin-bottom: 40px;
            section {
                display: flex;
                flex-flow: column nowrap;
                margin: 20px 0;
                h4 {
                    font-weight: normal;
                    margin: 0;
                }
                label {
                    margin-top: 20px;
                }
                input[type=text], input[type=email], textarea {
                    width: 300px;
                }
                select {
                    width: 250px;
                }
                .header-row {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }
                .date-picker {
                    display: flex;
                    flex-flow:row wrap;
                    align-items:center;
                    .react-datepicker-wrapper input {
                        width: 150px;
                    }
                }
                .phone-number-table {
                    border: 1px solid $DARKGRAY;
                    thead {
                        background-color: $MIDGRAY;
                        tr th { 
                            border-bottom: 1px solid $DARKGRAY;
                            border-left: 1px solid $DARKGRAY;
                            text-align: left;
                            padding: 8px 12px;
                            font-weight: 600;
                            &:first-child {
                                border-left: none;
                            }
                        }
                    }
                    tbody tr td {
                        border-left: 1px solid $DARKGRAY;
                        padding: 8px 12px;
                        cursor: pointer;
                        &:first-child {
                            border-left: none;
                        }
                    }
                }
                .table-area {
                    margin: 20px 0;
                }
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
                position: fixed;
                bottom: 0;
                background-color: white;
                right: 0;
                left: 0;
                border-top: 1px solid $DARKGRAY;
                padding: 6px 10px
            }
        }
    }
}

@media (max-width:1020px) {
    .longform_outer {
        .longform_section_links_outer {
            display: none;
        }
        .longform_sections_outer {
            width: 100%;
        }
    }
}