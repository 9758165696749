

.tablets-outer {
	display: flex;
	flex-flow: row nowrap;
	.tablets-inner {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		font-size: 14px;
		.tablet {
			margin: 10px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
			&.selected {
				border-bottom: 2px solid;
				font-weight: bold;
			}
		}
	}
}

.communication-area-outer {
	display: flex;
	flex-flow: row nowrap;
	.communication-area-inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		.communication-area {
			flex-direction: column;
		}
	}
}