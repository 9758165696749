@import './2023Base.scss';

.mapContainer {
    border: 1px solid black;
}

.mapDetailsPanel {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    overflow: auto;
    label {
        font-weight: 600;
        font-size: 14px;
    }
    .mapDetailArea {
        display: flex;
        flex-flow: column nowrap;
        .mapDetailSettings {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 10px 0;
            background-color: $LIGHTGRAY;
            label {
                font-weight: 300;
            }
            .sizeSection {
                margin-left: 10px;
                label {
                    font-size: 12px;
                }
                .sizeSetting {
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 12px;
                    input {
                        font-size: 12px;
                        height: 10px;
                        text-align: right;
                        &:hover {
                            border-width: 1px;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
}

.createVendorFields {
    display: flex;
    flex-flow: column nowrap;
}


    .resize-handle {
        width: 10px;
        height: 10px;
        background: #ffffff;
        border: 1px solid #333;
        position: absolute;
        border-radius: 50%;
        &.nw {
            top: -5px;
            left:-5px;
        }
        &.ne {
            top: -5px;
            right:-5px;
        }
        &.sw {
            bottom: -5px;
            left:-5px;
        }
        &.se {
            bottom: -5px;
            right:-5px;
        }
        &:hover {
            fill: #0080ff;
            cursor: se-resize;
        }
    }
