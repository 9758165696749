@import 'style/_base';

.messages-outer {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    justify-content: flex-start;
    width: calc(100% - 100px);
    border: 1px solid $DARKGRAY;
    margin: 50px;
    .messages-inner {
        display: flex;
        flex-flow: row nowrap;
        height: calc(100vh - 375px);
        width: 100%;
        .leftAppPanel {
            display: inline-flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            align-self: flex-start;
            width: 30%;
            height: 100%;
            .leftAppPanelContainer {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 1;
                align-self: flex-start;
                width: 100%;
                border-right: 1px solid $DARKGRAY;
                .leftAppPanelInner {
                    display: flex;
                    flex-flow: column nowrap;
                    width: 100%;
                    height: 100%;
                    justify-content: space-between;
                    .topNav {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        border-bottom: 1px solid $DARKGRAY;
                        padding: 10px;
                        .title {
                            font-size: 20px;
                        }
                        .sorter {
                            .sortHint {
                                float: left;
                            }
                            .sortIcon {
                                margin-left: 5px;
                                float: right;
                                cursor: pointer;
                            }
                        }
                    }
                    .middleNav {
                        display: flex;
                        flex-flow: row nowrap;
                        flex-grow: 1;
                        overflow: auto;
                        ul {
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            li {
                                height: 80px;
                                width: 100%;
                                margin: 0;
                                cursor: pointer;
                                .recentMessageContainer, .listItemContainer {
                                    height: 80px;
                                    width: 100%;
                                    .recentMessage, .listItem {
                                        font-size: 14px;
                                        border-bottom: 1px solid $DARKGRAY;
                                        height: 80px;
                                        padding: 0 10px;
                                        overflow: hidden;
                                        &.selected {
                                            background-color:$BLUE3;
                                        }
                                        &.noBorder {
                                            border-bottom: 0;
                                        }
                                        .recentMessageIcon, .listItemIcon {
                                            position: relative;
                                            top: 10px;
                                            font-size: 30px;
                                            color: lightgray;
                                            float: left;
                                            width: 15%;
                                            text-align:center;
                                            &.blue {
                                                color: $BLUE1;
                                            }
                                            .attn {
                                                font-size: 14px;
                                                color: $ERROR1;
                                                display: none;
                                                &.on {
                                                    display: block;
                                                }
                                            }
                                        }
                                        .recentMessageFlag {
                                            font-size: 22px;
                                            color: red;
                                            float: left;
                                            clear: left;
                                        }
                                        .recentMessageContent, .listItemContent {
                                            position: relative;
                                            top: 10px;
                                            float: right;
                                            width:100%;
                                            overflow: hidden;
                                            .usernameAndTime {
                                                overflow: hidden;
                                                .userName {
                                                    font-weight: bold;
                                                    float: left;
                                                }
                                                .msgTime {
                                                    float: right;
                                                }
                                            }
                                            .msgAndSource {
                                                .msgArea {
                                                    height: 47px;
                                                    overflow: hidden;
                                                    float: left;
                                                    width: 70%;
                                                }
                                                .sourceIconArea {
                                                    position: relative;
                                                    right: 0;
                                                    width:30%;
                                                    text-align:right;
                                                    img {
                                                        width: 100%;
                                                        max-width:90px;
                                                    }
                                                }
                                            }
                                            .headingLeft {
                                                overflow: hidden;
                                                height: 16px;
                                                float: left;
                                            }
                                            .headingRight {
                                                text-decoration: underline;
                                                color: $BLUE1;
                                                float: right;
                                                text-align:right;
                                                cursor: pointer;
                                            }
                                            .body {
                                                overflow: hidden;
                                                height: 20px;
                                                float: left;
                                                clear: left;
                                                &.bubble {
                                                    background-color:$BLUE1;
                                                    color: white;
                                                    border-radius: 5px;
                                                    padding: 4px 6px 1px;
                                                }
                                            }
                                            .footer {
                                                height: 20px;
                                                overflow: hidden;
                                                float: left;
                                                clear: left;
                                                width: 100%;
                                                white-space:nowrap;
                                                .footerButtons {
                                                    margin: 1px 3px;
                                                    float: right;
                                                    border-radius: 2px 2px 0 0;
                                                    .footerButton {
                                                        float: left;
                                                        width: 20px;
                                                        height: 17px;
                                                        text-align:center;
                                                        color: $BLUE1;
                                                        &:hover {
                                                            color: $BLUE2;
                                                            font-weight:bolder;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:hover {
                                            background-color: initial;
                                        }
                                    }
                                }
                                &.tall {
                                    height: 100px;
                                    .listItemContainer {
                                        height: 100px;
                                        .listItem {
                                            height: 100px;
                                            .listItemContent {
                                                .body {
                                                    height: 56px;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    background-color: $BLUE2;
                                }
                                &.selected {
                                    background-color: $BLUE2;
                                    .recentMessageContainer {
                                        border-left: 3px solid $BLUE1;
                                    }
                                }
                            }
                        }
                    }
                    .bottomNav {
                        height: 50px;
                        border-top: 1px solid $DARKGRAY;
                        text-align:center;
                        top: unset;
                        left: 10px;
                        right: 10px;
                        .content {
                            margin-top:10px;
                            color: $BLUE1;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .rightAppPanel {
            flex-flow: column nowrap;
            flex-grow: 1;
            width: 70%;
            height: 100%;
            display:flex;
            overflow: auto;
            &.withOverallButtons {
                bottom: 43px;
            }
            .chatInnerContainer {
                display: flex;
                flex-flow: column nowrap;
                height: 100%;
                justify-content: space-between;
                .topNav {
                    border-bottom: 1px solid $DARKGRAY;
                    .messagingContactInfo {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        .messagingContactIcon {
                            width: 50px;
                            font-size: 30px;
                            color: lightgray;
                            text-align:center;
                        }
                        .messagingContactContent {
                            display: flex;
                            flex-flow: column nowrap;
                            justify-content: space-between;
                            .userName {
                                font-weight: bold;
                                font-size: 20px;
                            }
                            .phoneNumber {
                                
                            }
                        }
                        .messagingContactDetails {
                            width: 50px;
                            font-size: 30px;
                            color: lightgray;
                            text-align:center;
                            cursor: pointer;
                        }
                    }
                }
                .middleNav {
                    display: flex;
                    flex-flow: column nowrap;
                    flex-grow: 1;
                    overflow: hidden;
                    .chatView {
                        display: flex;
                        flex-flow: column nowrap;
                        flex-grow: 1;
                        overflow: auto;
                        height: calc(100vh - 120px);
                        .chatViewContainer {
                            display: flex;
                            flex-flow: column nowrap;
                            flex-grow: 1;
                            background: white;
                            overflow-x:scroll;
                            border-bottom: 1px solid $DARKGRAY;
                            border-left: 1px solid $DARKGRAY;
                            ul {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                li {
                                    float: left;
                                    clear: both;
                                    width:100%;
                                    margin:0;
                                    .chatBubble {
                                        .msgContainer {
                                            float: left;
                                            border-radius: 15px;
                                            margin: 5px;
                                            padding: 5px 10px;
                                            background: #eceff1;
                                            max-width: 70%;
                                            font-size: 14px;
                                            &.outbound {
                                                float: right;
                                                background-color: $BLUE2;
                                                a {
                                                    color: $BLACK;
                                                }
                                            }
                                            div {
                                                white-space: normal;
                                            }
                                            img {
                                                max-width:230px;
                                                padding: 5px;
                                                height: 175px;
                                            }
                                            span.fa.fa-flag {
                                                margin: 0 5px;
                                                &.important {
                                                    color: #e5001c;
                                                }
                                                &.urgent {
                                                    color: #ee601a;
                                                }
                                                &.todo {
                                                    color: #52187e;
                                                }
                                                &.reminder {
                                                    color: #000;
                                                }
                                                &.complete {
                                                    color: #169b3f;
                                                }
                                            }
                                        }
                                        .msgStatus {
                                            float: left;
                                            clear: both;
                                            color: $MIDGRAY;
                                            font-size: 12px;
                                            margin: 0 10px;
                                            &.outbound {
                                                float: right;
                                            }
                                            &.alert {
                                                color: red;
                                            }
                                        }
                                        .msgMore {
                                            float: left;
                                            color: $MIDGRAY;
                                            font-size: 12px;
                                            cursor: pointer;
                                            &.outbound {
                                                float: right;
                                            }
                                        }
                                    }
                                    &.queued {
                                        background: none;
                                        border-radius: 0;
                                        max-width: none;
                                        padding: 0;
                                        margin: 0;
                                        width:100%;
                                        text-align:center;
                                        .qtitle {
                                            background-color: black;
                                            color: white;
                                            span {
                                                cursor: pointer;
                                                padding-left: 5px;
                                            }
                                        }
                                        .qmsg {
                                            margin-bottom: 4px;
                                        }
                                    }
                                    &.timestamp {
                                        background: none;
                                        font-size: 16px;
                                        text-align:center;
                                        width: 100%;
                                        margin: 0;
                                        padding: 0;
                                        max-width: none;
                                    }
                                }
                            }
                        }
                    }
                    .newMessageAreaContainer {
                        display: flex;
                        flex-flow: column nowrap;
                        height: 120px;
                        .textAreaContainer {
                            textarea {
                                width:100%;
                                height: 70px;
                                resize:none;
                                font-family: 'Roboto', sans-serif;
                                font-size: 16px;
                                padding: 0;
                                border: none;
                                border-radius: 0;
                            }
                        }
                    }
                }
                .bottomNav {
                    height: 50px;
                    border-top: 1px solid $DARKGRAY;
                    text-align:center;
                    display:flex;
                    flex-flow: row nowrap;
                    justify-content: space-evenly;
                    div {
                        display:inline-block;
                        width: 40px;
                        height: 21px;
                        margin: 10px 0;
                        padding: 5px 0 0;
                        border-radius: 10px;
                        cursor: pointer;
                        &:last-child {
                            color: $WHITE;
                            background-color: $BLUE1;
                        }
                        &.disabled {
                            background-color: #979ca0;
                            color: $BLACK;
                        }
                        &:hover {
                            color: $WHITE;
                            background-color: #979ca0;
                            &:last-child {
                                background-color: $BLUE2;
                                color: $BLUE1;
                            }
                            &.disabled {
                                background-color: #979ca0;
                                color: $BLACK;
                            }
                        }
                        
                    }
                    button {
                        height: 26px;
                        margin: auto 0;
                    }
                }
            }
            &.scheduleMessage {
                input {
                    font-size:24px !important;
                    text-align:center;
                    &::placeholder {
                        font-size: 12px !important;
                        text-align:left;
                        position:relative;
                        top: -10px;
                    }
                }
                .scheduledMessageList {
                    border-top: 1px solid $DARKGRAY;
                    border-bottom: 1px solid $DARKGRAY;
                    margin: 0 10px;
                    height: 225px;
                    overflow: auto;
                    ul {
                        width: 100%;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li {
                            margin: 0;
                            height: 50px;
                            &.noMessages {
                                text-align:center;
                                font-weight: 18px
                            }
                            .scheduledMessageContainer {
                                height: 50px;
                                width: 100%;
                                .scheduledMessage {
                                    font-size: 14px;
                                    border-bottom: 1px solid $DARKGRAY;
                                    height: 50px;
                                    margin: 0 10px;
                                    .scheduledMessageIcons {
                                        font-size: 30px;
                                        color: $BLUE1;
                                        float: left;
                                        width: 15%;
                                        text-align:center;
                                    }
                                    .scheduledMessageContent {
                                        float: right;
                                        width:85%;
                                        .msgTime {
                                            overflow: hidden;
                                            height: 16px;
                                            float: left;
                                        }
                                        .msgRmve {
                                            text-decoration: underline;
                                            color: $BLUE1;
                                            float: right;
                                            text-align:right;
                                            cursor: pointer;
                                        }
                                        .msgText {
                                            overflow: hidden;
                                            height: 20px;
                                            float: left;
                                            clear: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .scheduledMessageOptions, .composeMessageOptions {
                    border-bottom: 1px solid $DARKGRAY;
                    margin: 0 10px;
                    padding: 10px 0;
                    .newMessageAreaContainer, .composeMessageSection {
                        border-top: 1px solid $DARKGRAY;
                        margin-top: 10px;
                        padding: 10px 0;
                        .textAreaContainer {
                            height: 100%;
                            textarea {
                                width:100%;
                                height: 100%;
                                resize:none;
                                font-family: 'Roboto', sans-serif;
                                font-size: 16px;
                                padding: 0;
                                border: none;
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
            
            &.mediaContainer {
                display: flex;
                flex-flow: column nowrap;
                height: 100%;
                justify-content: space-between;
                .mediaItemsContainer {
                    display: flex;
                    flex-flow: row;
                    flex-grow: 1;
                    overflow: auto;
                    .mediaItems {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        li {
                            height: 210px;
                            width: 160px;
                            border: 1px solid $DARKGRAY;
                            cursor: pointer;
                            margin: 5px;
                            display: flex;
                            &.mediaItem {
                                display: flex;
                                flex-flow: column nowrap;
                                .mediaItemContainer {
                                    width: 160px;
                                    height: 200px;
                                    
                                }
                                &:hover {
                                    box-shadow: 10px 5px 5px $BLUE1;
                                    .mediaItemDeleter {
                                        display: block;
                                    }
                                }
                                .mediaActions {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                }
                            }
                            .mediaDropZone {
                                padding: 0;
                                border: 0;
                                width: 100%;
                                text-align:center;
                            }
                        }
                    }
                }
            }
            .calendarContainer {
                position: relative;
                left: 0;
                top: 0;
                width: 100%;
                border-right: 1px solid $DARKGRAY;
                overflow: auto;
            }
            .dailyMessageList {
                position: relative;
                bottom: 0;
                left: 0;
                right: 0;
                height: 30%;
                overflow: auto;
                ul {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    li {
                        height: 80px;
                        width: 100%;
                        margin: 0;
                        cursor: pointer;
                        
                        .recentMessageContainer, .listItemContainer {
                            height: 80px;
                            width: 100%;
                            .recentMessage, .listItem {
                                font-size: 14px;
                                border-bottom: 1px solid $DARKGRAY;
                                height: 80px;
                                margin: 0 10px;
                                &.noBorder {
                                    border-bottom: 0;
                                }
                                .recentMessageIcon, .listItemIcon {
                                    position: relative;
                                    top: 10px;
                                    font-size: 30px;
                                    color: lightgray;
                                    float: left;
                                    width: 15%;
                                    text-align:center;
                                    &.blue {
                                        color: $BLUE1;
                                    }
                                    .attn {
                                        font-size: 14px;
                                        position: relative;
                                        top: 0;
                                        left: 5px;
                                        color: $CORAL1;
                                        display: none;
                                        &.on {
                                            display: block;
                                        }
                                    }
                                }
                                .recentMessageContent, .listItemContent {
                                    position: relative;
                                    top: 10px;
                                    float: right;
                                    width:100%;
                                    overflow: hidden;
                                    .usernameAndTime {
                                        overflow: hidden;
                                        .userName {
                                            font-weight: bold;
                                            float: left;
                                        }
                                        .msgTime {
                                            float: right;
                                        }
                                    }
                                    .msgAndSource {
                                        .msgArea {
                                            height: 47px;
                                            overflow: hidden;
                                            float: left;
                                            width: 70%;
                                        }
                                        .sourceIconArea {
                                            position: relative;
                                            right: 0;
                                            width:30%;
                                            text-align:right;
                                            img {
                                                width: 100%;
                                                max-width:90px;
                                            }
                                        }
                                    }
                                    .headingLeft {
                                        overflow: hidden;
                                        height: 16px;
                                        float: left;
                                    }
                                    .headingRight {
                                        text-decoration: underline;
                                        color: $BLUE1;
                                        float: right;
                                        text-align:right;
                                        cursor: pointer;
                                    }
                                    .body {
                                        overflow: hidden;
                                        height: 20px;
                                        float: left;
                                        clear: left;
                                    }
                                    .footer {
                                        height: 20px;
                                        overflow: hidden;
                                        float: left;
                                        clear: left;
                                        width: 100%;
                                        white-space:nowrap;
                                        .footerButtons {
                                            margin: 1px 3px;
                                            float: right;
                                            border-radius: 2px 2px 0 0;
                                            .footerButton {
                                                float: left;
                                                width: 20px;
                                                height: 17px;
                                                text-align:center;
                                                color: $BLUE1;
                                                &:hover {
                                                    color: $BLUE2;
                                                    font-weight:bolder;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    background-color: initial;
                                }
                            }
                        }
                        &.tall {
                            height: 100px;
                            .listItemContainer {
                                height: 100px;
                                .listItem {
                                    height: 100px;
                                    .listItemContent {
                                        .body {
                                            height: 56px;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            background-color: $BLUE2;
                        }
                        &.selected {
                            background-color: $BLUE2;
                            .recentMessageContainer {
                                border-left: 3px solid $BLUE1;
                            }
                        }
                    }
                }
            }
            .composeContainer {
                position: relative;
                left: 0;
                right: 0;
                height: 50%;
                bottom: 0;
                border-top: 1px solid $DARKGRAY;
            }
            .settingsSection {
                margin:0 10px;
                border-top: 1px solid $DARKGRAY;
            }
            .anchoredBottomPane {
                position: relative;
                bottom: 0;
            }
        }
    }
}
.messageCharacterCounter {
    font-size: 12px;
}
.phoneNumberChooser {
    ul {
        list-style: none;
        li:hover {
            font-weight: bold;
            cursor: pointer;
        }
    }
}