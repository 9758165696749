@import './base';

.tab-container {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid $BLUE2;
    .tab {
        border: 1px solid $BLUE2;
        border-bottom: none;
        padding: 10px 20px;
        background-color: $BLUE3;
        cursor: pointer;
        &:first-child {
            margin-left: 40px;
            border-radius: 4px 0 0 0;
        }
        &:last-child {
            border-radius: 0 4px 0 0;
        }
        &.selected {
            background-color: white;
        }
    }
}