@import './_base';

.data-import {
    width: 350px;
    .drop-zone {
        text-align:center;
        border:2px dashed $DARKGRAY;
        padding:10px;
        width:340px;
        margin:auto;
    }
    .file-input {
        display: none;
    }
    .rejected-lines {
        height:100px;
        overflow-y:auto;
        text-align:center;
    }
}
.field-map {
    font-size: 12px;
    table {
        width:340px;
        margin:auto;
        .fieldsNode {
            border: 1px solid $DARKGRAY;
	        margin: 4px;
	        padding: 4px;
            height:200px;
            overflow-y:scroll;
            float:left;
            width:120px;
            .field {
                font-size: 12px;
                border: 1px solid $DARKGRAY;
                border-radius: 5px;
                margin: 2px;
                cursor: pointer;
                text-align: center;
                &.selected {
                    background-color: lightgreen;
                    border-color: green;
                }
                &.linked {
                    background-color: lightcoral;
                    border-color: coral;
                }
            }
        }
    }
}