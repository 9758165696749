@import 'style/_base';

.contactSearchResults {
	border-top: 1px solid $DARKGRAY;
	border-bottom: 1px solid $DARKGRAY;
	margin: 0 10px;
	height: 225px;
	overflow: auto;
	ul {
        list-style: none;
        margin: 0;
        padding: 0;
		width: 100%;
		li {
			margin: 0;
			height: 50px;
			&.noResults {
				text-align:center;
				font-weight: 18px
			}
			.contactSearchResult {
				height: 50px;
				width: 100%;
				cursor: pointer;
				&:hover {
					background-color: $BLUE2;
				}
                &.selected {
                    background-color: $BLUE2;
                }
				.searchResult {
					font-size: 14px;
					border-bottom: 1px solid $DARKGRAY;
					height: 50px;
					padding: 0 10px;
					.searchResultIcon {
						position: relative;
						top: 10px;
						font-size: 30px;
						color: $BLUE1;
						float: left;
						width: 15%;
						text-align:center;
					}
					.searchResultContent {
						position: relative;
						top: 10px;
						float: right;
						width:100%;
						.searchResultLine1 {
							overflow: hidden;
							height: 16px;
							float: left;
						}
						.searchResultLine2 {
							overflow: hidden;
							height: 20px;
							float: left;
							clear: left;
						}
						.crmLogo {
							float: right;
						}
					}
				}
			}
		}
	}
}