@import './_base';

.notifications {
    position: fixed;
    top: 20px;
    left: 20%;
    z-index: 2000;
    display: flex;
    flex-flow: row nowrap;
    width: 60%;

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        li {
            background-color: $PRIMARY;
            border: 1px solid $PRIMARY;
            border-radius: 4px;
            margin: 5px 0;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            min-height: 40px;
            align-items: center;
            &.success {
                background-color: $SUCCESS; 
                border-color: $SUCCESS;
            }
            &.error {
                background-color: $DANGER; 
                border-color: $DANGER;
            }
            &.warning {
                background-color: $WARNING; 
                border-color: $WARNING;
            }
            .message {
                font-size: 16px;
                margin: 10px;
            }
            .dismiss {
                margin: 10px;
                cursor: pointer;
                white-space: nowrap;
            }
        }
    }
}