@import '_base';

.csvImportOuter {
    display: flex;
    flex-flow: column nowrap;
    width: 600px;
    height: 400px;
    margin: auto;
    border: 1px solid $MIDGRAY;
    .csvImportInner {
        display: flex;
        flex-flow: column nowrap;
        margin: 10px;
        //border: 1px solid $MIDGRAY;
        overflow: auto;
        .csvFileImportArea {
            margin: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $MIDGRAY;
            .drop-zone {
                text-align:center;
                border:2px dashed $DARKGRAY;
                padding:10px;
                width:340px;
                margin:auto;
            }
            .file-input {
                display: none;
            }
            .selectDifferentFile {
                display: flex;
                justify-content: center;
            }
        }

        .csvElementsArea {
            display: flex;
            flex-flow: column nowrap;
            margin: 10px;
            .noFieldsMessage {
                text-align: center;
            }
            .csvElementsHeader {
                display: flex;
                justify-content: space-between;
                h5 {
                    padding: 0;
                    margin: 0;
                }
            }
            ul {
                display: flex;
                flex-flow: column nowrap;
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    border-bottom: 1px solid $MIDGRAY;
                    .source {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                    }
                    .target {

                    }
                }
            }
        }
    }
}