@import 'style/_base';

body {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    #root {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .outer-root-container {
            position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        }
    }
}

.login-container {
	display: flex;
	flex-flow: column nowrap;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	.logo-container {
        display: flex;
        flex-flow: row nowrap;
        background-color: $NAVY1;
        justify-content: center;
        img {
            height: 96px;
            margin: 15px;
        }
	}
    .login-center-container {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        justify-content: center;
        .login-center-pane-outer {
            width: 650px;
            .login-heading {
                display: flex;
                justify-content: center;
                color: $CORAL1;
            }
            .login-center-pane-inner {
                display: flex;
                flex-flow: column;
                border: 1px solid $MIDGRAY;
                border-radius: 5px;
                box-shadow: 0 3px 6px #00000029;
                margin: 50px;
                padding: 30px 50px;
                h2 {
                    margin: 0;
                    font-weight: normal;
                }
                .signup-container {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content:center;
                    div {
                        padding: 0 10px;
                    }
                }
                .passwordLabelRow {
                    margin-top: 10px;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: space-between;
                    label {
                        margin-top: unset;
                    }
                }
                .option-container {
                    display: flex;
                    label {
                        margin-top: unset;
                    }
                }
            }
        }
    }
	.form-container {
		display: flex;
		flex-flow: column nowrap;
		margin-top: 20px;
		padding-bottom: 100px;
		.password-container {
			display: none;
			margin-top: 20px;
		}
		.next-button-container {
			margin-top:50px;
		}
		.mfa-setup-container {
			display: none;
			flex-flow: column nowrap;
		}
		.mfa-code-container {
			display: none;
			flex-flow: column nowrap;
		}
	}
	input {
		width: 100%;
		position: relative;
		left: 0;
		right: 0;
	}
	.input-container {
		display: flex;
		flex-flow: row nowrap;
        .verified-username-container {
            padding: 0 0 10px 0;
            .verified-username {
                font-weight: bold;
            }
        }
	}
	.copyright-container {
		display: flex;
		flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
		font-size: 16px;
		height: 50px;
		padding: 20px;
		background: $LIGHTGRAY;
		.policiesContainer a {
			margin-right: 15px;
		}
	}
}
.password-stuff {
	font-size:18px;
	width:100%;
	padding: 10px 0;
	height: 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	input {width: unset;}
	.arrContainer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
        font-size:14px;
	}
}
.input-error {
	color: red;
	font-size: smaller;
}
