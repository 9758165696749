@import 'style/_base';

.landing-container {
    display: flex;
	flex-flow: column nowrap;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    .landing-main{
        display: flex;
        flex-flow: row;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .landing-left {
            display: flex;
            flex-flow: column nowrap;
            flex: 0 1 20%;
            width: 400px;
            min-width: 400px;
            background-color: white;
            .landing-logo {
                width: 300px;
                margin: 30px auto;
            }
            .landing-title {
                width: 300px;
                margin: 30px auto;
                font-size: 28px;
                font-weight: 600;
                text-align: center;
                color: $CORAL1;
            }
            .landing-description {
                width: 300px;
                margin: 0 auto;
                color: $NAVY1;
            }
            .landing-button {
                width: 300px;
                margin: 20px auto;
                display: flex;
                justify-content: center;
            }
            .landing-legal {
                width: 300px;
                margin: 20px auto;
                display: flex;
                justify-content: space-around;
            }
            .landing-support {
                width: 300px;
                margin: 20px auto;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                color: $NAVY1;
            }
            a {
                color: $NAVY1;
            }
            .landing-copy {
                position: fixed;
                bottom: 0;
                margin: 10px auto;
                background-color: white;
                width: 400px;
                text-align: center;
            }
        }
        .landing-right {
            display: flex;
            flex: 1;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/carshow.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

@media (max-width: 768px) {
    .landing-container {
        .landing-main {
            .landing-left {
                margin: 0 auto;
            }
            .landing-right {
                display: none;
            }
        }
    }
}