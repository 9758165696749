@import 'style/_base';

.analytic-dashboard {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	.analytic-panels_row {
		display: flex;
		flex-flow: row nowrap;
		flex-grow: 1;
		background: $LIGHTGRAY;
		.analytic-group {
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;
            border-radius: 5px;
			margin: 20px;
			background: white;
			//box-shadow: 5px 5px 5px $DARKGRAY;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            max-height:569px;
			.analytic-title {
				font-size: 24px;
				font-weight: 600;
				padding: 10px 10px 0 10px;
                color: $CORAL1
			}
			.analytic-subtitle {
				font-size: 10px;
				padding: 0 0 0 10px;
				color: $DARKGRAY;
			}
            .analytics-row {
                display: flex;
				flex-flow: row nowrap;
                flex: 1;
                justify-content: space-around;
            }
			.analytic {
				display: flex;
				flex-flow: column nowrap;
                flex: 1;
				padding: 10px;
				margin: 10px 15px;
				//border: 1px solid $DARKGRAY;
                border-radius: 5px;
				//box-shadow: 10px 5px 5px $DARKGRAY;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
				justify-content: center;
				align-items: center;
				.analytic-data {
					font-size: 48px;
					font-weight: 400;
				}
				.analytic-label {
					color: $DARKGRAY;
				}
				&.live {
					cursor: pointer;
					&:hover {
						box-shadow: 10px 5px 5px $MIDGRAY;
					}
				}
			}
            .apexcharts-canvas {
                margin: auto;
            }
		}
	}
}