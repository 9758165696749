.dnd-box {
    text-align:center;
    border:2px dashed gray;
    padding:10px;
    width:330px;
    height:100px;
}

.file-list {
    list-style:none;
    padding:0;
    margin:5px;
}