@import './base';

.mini-list {
	display: flex;
	flex-direction: column;
	height: 100px;
	border: 1px solid $BLUE2;
    overflow: auto;
    font-size: 14px;
    .mini-list-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        cursor: pointer;
        div {
            display: flex;
            flex-flow: row nowrap;
            overflow: hidden;
            white-space: nowrap;
            padding: 0 3px;
            .tag-row {
                display:flex;
                flex-flow:row wrap;
                background-color:black;
                color:white;
                border-radius:5px;
                font-size:13px;
                margin:2px;
                padding:2px 6px;
                &.add {
                    background-color: green;
                }
                .selector {
                    cursor:pointer;
                    margin-top:2px;
                    margin-left:5px;
                }
            }
        }
        &.no-rows {
            justify-content: center;
        }
    }
}

.alpha-pad {
    display: flex;
    flex-flow: row wrap;
    .letter {
        display: flex;
        border: 1px solid $MIDGRAY;
        margin: 4px;
        padding: 4px;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        &.selected {
            background-color: $NAVY1;
            color: white;
        }
    }
}