@import 'style/_base';

.invoice-page {
	background-color: gray;
	margin: 0;
	padding: 0;
}
.invoice-outer {
	background-color: white;
	height: 11in;
	width: 8.5in;
	margin-bottom: 5px;
	.invoice-inner {
		padding: .5in .75in;
        height: 10in;
        .logo-container {
            width: 550px;
            margin: auto;
        }
        p {
            padding: 10px;
            text-align:center;
        }
        .invoice-container {
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            .addr {
                padding: 10px;
                font-size: 14px;
                div:first-child {
                    font-weight: bold;
                }
            }
            .invoice {
                padding: 40px 10px;
                font-size: 14px;
                table {
                    border: 1px solid $NAVY1;
                    width: 100%;
                    tbody {
                        tr {
                            line-height: 30px;
                            th {
                                background-color: $NAVY1;
                                color: white;
                                text-align:left;
                                padding: 0 5px;
                            }
                            td {
                                padding: 0 5px;
                            }
                            &:last-child {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
	}
}