@import './_base';

.table-area {
    display: flex;
    flex-flow: column nowrap;
    margin: 20px 40px;
    .table-search-and-create-container {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
        height: 36px;
        justify-content: space-between;
        border-bottom: 1px solid;
        .table-filters {
            display: flex;
            flex-flow: row nowrap;
            section {
                display: flex;
            }
            label {
                padding: 4px;
                font-size: 14px;
                white-space: nowrap;
                align-content: center;
                display: flex;
                flex-flow: row wrap;
                margin: 0;
                &.filter-link {
                    color: $LINK;
                    cursor: pointer;
                }
            }
        }
    }
    .table-filters-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        .create-button-container {
            display: flex;
            align-content: center;
        }
        .table-actions {
            display: flex;
            flex-flow: row nowrap;
            .icon-button {
                color: $LINK;
            }
        }
    }
    .table-column-select {
        border: 1px solid $DARKGRAY;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: auto;
        display: flex;
        flex-flow: column wrap;
        &.close {
            height: 0;
            opacity: 0;
            transition: 'height 0.5s, opacity 0.2s';
        }
        &.open {
            height: 100px;
            opacity: 1;
            transition: 'height 0.5s, opacity 0.2s';
        }
    }
    .table-container {
        display: flex;
        flex-flow: row nowrap;
        overflow-x: auto;
        /*margin-top: 10px;*/
        border: 1px solid $BLUE2;
        min-height: 200px;
        .table-outer {
            display: flex;
            flex-flow: column nowrap;
            .table-header {
                display: flex;
                flex-flow: row nowrap;
                background-color: $BLUE3;
                justify-content: flex-start;
                div {
                    border-bottom: 1px solid $BLUE2;
                    border-right: 1px solid $BLUE2;
                    width: 200px;
                    padding: 10px;
                    font-size: 13px;
                    font-weight: bold;
                    text-decoration: uppercase;
                    &.checkbox-col {
                        width: 18px;
                        text-align:center;
                        .dart-checkbox {
                            font-size:18px!important;
                            border:none;
                            width:unset;
                            display:flex;
                            padding:0;
                            margin:0!important;
                        }
                    }
                    &.hide {
                        display:none;
                    }
                }
            }
            .table-rows {
                display: flex;
                flex-flow: column nowrap;
                background-color: white;
                justify-content: flex-start;
                .table-loading {
                    font-size: 24px;
                    padding: 10px;
                }
                .contact-row {
                    display: flex;
                    flex-flow: row nowrap;
                    background-color: white;
                    justify-content: flex-start;
                    border-bottom: 1px solid $BLUE2;
                    cursor: pointer;
                    max-height: 38px;
                    div {
                        border-right: 1px solid white;
                        width: 200px;
                        padding: 10px;
                        font-size: 13px;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;
                        flex-flow: row nowrap;
                        &.checkbox-col {
                            width: 18px;
                            text-align:center;
                            .dart-checkbox {
                                font-size:18px!important;
                                border:none;
                                width:unset;
                                display:flex;
                                padding:0;
                                margin:0!important;
                            }
                        }
                        &.pillbox {
                            flex-wrap: wrap;
                        }
                        &.hide {
                            display:none;
                        }
                        &:last-child {
                            border-right: 1px solid $BLUE2;
                        }
                        &:hover button {
                            display: block;
                        }
                        &.icon-button {
                            padding: 0;
                            height: 40px;
                            color: $BLUE1;
                            div {
                                border-right: none;
                                padding: 0;
                                &.icon-button-contents {    
                                    display: flex;
                                    flex-flow: row nowrap;
                                    .icon-container {
                                        width: unset;
                                        padding: 0 4px 0 0;
                                    }
                                    .label-container {
                                        width: unset;
                                    }
                                }
                            }
                            &:hover {
                                font-weight: 800;
                            }
                        }
                        .tag {
                            display:flex;
                            flex-flow: row wrap;
                            align-content: center;
                            background-color: $CORAL2;
                            border: 1px solid $MIDGRAY;
                            border-radius: 15px;
                            font-size:10px;
                            width:unset;
                            margin: 0 2px;
                            padding:2px 4px;
                        }
                    }
                    &:hover {
                        background-color: $BLUE3;
                    }
                    &:focus {
                        div {
                            background-color: $LIGHTGRAY;
                        }
                    }
                }
            }
        }
    }
    .table-has-more {
        display: flex;
        justify-content: center;
    }
    .table-pagination {
        display: flex;
        flex-flow: row nowrap;
        justify-content:center;
        margin-top: 4px;
        color: $LINK;
        .pagination {
            display: flex;
            flex-flow: row nowrap;
            cursor: pointer;
            padding: 0 10px;
            &.disabled {
                color: $DARKGRAY;
                cursor: not-allowed;
            }
        }
        .page-number {
            display: flex;
            flex-flow: row nowrap;
            padding: 2px 6px;
            background-color: $LINK;
            color: $WHITE;
            border: 1px solid $LINK;
            border-radius: 10px;
            font-size: 12px;
            justify-content:center;
        }
    }
}

.amazing-search-outer {
    display: flex;
    flex-flow: row nowrap;
    border: 2px solid $CORAL1;
    border-radius: 4px;
    .amazing-search-inner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        input {
            padding: 4px;
            border: none;
            border-radius: 2px 0 0 2px;
            &:focus {
                outline: none;
            }
        }
        .search-button {
            border-left: 1px solid;
            padding: 4px 4px 4px 6px;
            background-color: $CORAL1;
            color: $WHITE;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
        }
    }
}