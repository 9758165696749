@import './2023Base.scss';

.dashcard-design-studio {
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    .dashcard-settings {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        //background-color: $LIGHTGRAY;
        align-items: center;
        .breadcrumb {
            position: fixed;
            top: 100px;
            left: 0;
            background-color: $BLUE3;
            border-top: 1px solid $BLUE2;
            border-right: 1px solid $BLUE2;
            border-bottom: 1px solid $BLUE2;
            color: $BLUE2;
            border-radius: 0 4px 4px 0;
            text-align:center;
            cursor: pointer;
            .breadcrumb-label {
                margin: 10px;
                font-size: 13px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        .dashcard-name-container {
            //margin-left: auto;
        }
    }
    .dashcard-editor-container {
        display: flex;
        flex: 1;
        overflow: hidden;
        .dashcard-editor-area {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            width: 100%;
            overflow: auto;
            background-color: $DARKGRAY; /* Optional: background color for visibility */
            .paper-container {
                position: relative;
                width: 100%;
                height: 100%;
                margin: 10px;
                
            }            
        }
        .dashcard-tools-area {
            display: flex;
            flex-flow: column nowrap;
            overflow: auto;
            height: 100%;
            width: 250px;
            border-left: 1px solid $MIDGRAY;
            padding: 0 10px;
            .tiles-view {
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                .tiles {
                    display: flex;
                    flex-flow: row wrap;
                    .tile {
                        width: 100px;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $DARKGRAY;
                        border-radius: 5px;
                        margin: 5px;
                        padding: 5px;
                        cursor: grab;
                        .tile-icon {
                            font-size: 48px;
                        }
                        .tile-label {

                        }
                        &:hover {
                            background-color: $MIDGRAY;
                            color: white;
                        }
                    }
                }
            }
            .box-details {
                display: flex;
                flex-flow: column nowrap;
                flex: 1;
                .box-actions {
                    display: flex;
                    background-color: $DARKGRAY;
                    color: white;
                    justify-content: space-between;
                }
            }
        }
    }
}

.paper {
    position: relative;
    width: 11in;
    height: 8.5in;
    background-color: white;
    border: 1px dashed $MIDGRAY;
    z-index: 100;
    .grid-lines {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 20px 20px; /* Adjust the grid size as needed */
        background-image: linear-gradient(to right, #ccc 1px, transparent 1px),
                          linear-gradient(to bottom, #ccc 1px, transparent 1px);
        z-index: 1; /* Ensure grid lines are behind the content */
    }
    .box {                        
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        height:100%;
        cursor: move; /* Change cursor to indicate draggable */
        z-index: 100;
        .image-box {
            width:100%;
            height:100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 200;
        }
        .line-box {
            width: 100%;
            z-index: 75;
        }
        .border-box {
            width: 100%;
            height: 100%;
            margin: 5px;
            z-index: 50;
            .border-box-background {
                width:100%;
                height:100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
        .registration-box {
            font-style: italic;
            z-index: 100;
        }
        .qrcode-box {
            display: flex;
            flex-flow: row nowrap;
            border: 1px solid $MIDGRAY;
            justify-content: space-evenly;
            min-height: 120px;
            .qrcode-instructions {
                display: flex;
                width: 50%;
                padding: 5px;
                align-items: center;
                text-align: center;
            }
            .qrcode-code {
                display: flex;
                width: 50%;
                padding: 5px;
                align-items: center;
                justify-content: center;
            }
        }
        &.selected {
            outline: 1px solid red;
        }
    }
}


.dashcard-tile-panel {
    .tile-details {
        display: flex;
        flex-flow: column nowrap;
        .mapDetailSettings {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 10px 0;
            background-color: $LIGHTGRAY;
            label {
                font-weight: 300;
            }
            .sizeSection {
                margin-left: 10px;
                label {
                    font-size: 12px;
                }
                .sizeSetting {
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 12px;
                    input {
                        font-size: 12px;
                        height: 10px;
                        text-align: right;
                        &:hover {
                            border-width: 1px;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
}

