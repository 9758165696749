@import './base';

.pill-box-outer {
	display: flex;
	flex-direction: column;
	height: 200px;
	border: 1px solid $BLUE2;
    overflow: auto;
    font-size: 14px;
    .pill-box-inner {
        display: flex;
        flex-flow: row wrap;
        .pill {
            display: flex;
            flex-flow: row wrap;
            border: 1px solid $MIDGRAY;
            border-radius:30px;
            margin: 4px;
            padding: 4px 10px;
            cursor: pointer;
            align-items: center;
            .pill-label {
                padding: 0 4px;
            }
            &:hover {
                background-color: $CORAL3;
                border-color: $DARKGRAY;
            }
            &.selected {
                background-color: $CORAL2;
            }
        }
        &.empty {
            justify-content: center;
        }
        .empty {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            width: 100%;
        }
    }
}