@import 'style/_base';

.signup {
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-flow: row nowrap;
    .signup-inner {
        display: flex;
        flex-flow: column nowrap;
        width:950px;
        margin:50px auto;
        min-height: 600px;
        .signup-title-area {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            .signup-logo-container {
                display: flex;
                justify-content: center;
                img {
                    width: 640px;
                }
            }
            .signup-title-container {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                .signup-sub-head {
                    text-align:center;
                    font-size:20px;
                    font-weight:700;
                    .linkButton {
                        font-size:20px;
                        font-weight:700;
                        text-decoration: underline;
                        &:hover {
                            background-color: unset;
                        }
                    }
                }
            }
        }
        .signup-slide-area {
            display: flex;
            flex-flow: row;
            justify-content: center;
        }
        
        ul {
            list-style: none;
            margin: auto;
            padding: 0;
            width:625px;
            li {
                margin: 4px;
                .label {
                    text-transform: uppercase;
                }
                input {
                    &::placeholder {
                        font-size: 12px;
                        position:relative;
                        top: -10px;
                    }
                }
            }
        }
    }
}