@import 'style/_base';

.setup {
	.outerContainer {
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		min-width: 1200px;
		min-height: 904px;
		.leftContainer {
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			width:300px;
			color:white;
			background-color: $NAVY1;
			.logoContainer {
				width:200px;
				margin:100px auto;
				img {
					width: 200px;
				}
			}
			.listContainer {
				width: 250px;
				margin: auto;
				ul {
					width: 250px;
					li {
						height: 30px;
						div.setupStep {
							color: white;
							margin: 15px 0;
							font-size: 20px;
							.todo {
								visibility: hidden;
							}
						}
					}
				}
			}
			.actionsContainer {
				position:absolute;
				left: 25px;
				bottom: 50px;
				width: 250px;
				height: 100px;
				ul {
					width: 250px;
					li {
						height: 20px;
						div.actionItem {
							color: white;
							margin: 15px 0;
							cursor: pointer;
							&::hover {
								font-weight: bold;
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.rightContainer {
			position:absolute;
			top:0;
			bottom:0;
			right:0;
			left:300px;
			color:$BLACK;
			.mainTitle {
				text-align:center;
				font-size:32px;
				margin: 20px 0;
			}
			.setupItemTitle {
				text-align:center;
				font-size:28px;
				margin: 15px 0;
			}
			.setupItemContainer {
				border-top: 1px solid $BLACK;
				position: absolute;
				top: 130px;
				right: 0;
				left: 0;
				bottom: 0;
				
				.setupInstructions {
					font-size: 24px;
					margin: 10px 50px;
					text-align:center;
					ul {
						width: initial;
					}
				}
				.setupPane {
					position: absolute;
					left: 20px;
					right: 20px;
					top: 200px;
					bottom: 20px;
					background-color: white;
					border: 1px solid $BLACK;
					box-shadow: 10px 10px 5px grey;
					&.left {
						right: initial;
						width: 47%;
					}
					&.right {
						left: initial;
						width: 47%;
					}
					.setupPaneTitle {
						text-align:center;
						font-size: 32px;
						margin: 20px 0; 
					}
                    .bigIcon {
                        font-size: 100px;
                        color: $CORAL1;
                        display: flex;
                        justify-content: center;
                    }
					.setupPaneList {
						text-align:center;
						overflow: auto;
						position: absolute;
						top: 66px;
						bottom: 37px;
						left: 0;
						right: 0;
						.setupPaneListTitle {
							font-size: 28px;
						}
						ul {
							width: initial;
							margin-bottom: 10px;
                            list-style: none;
							li {
								font-size: 20px;
							}
						}
						.setupPaneFootnote {
							font-size: 14px;
						}
					}
					.setupPaneButtons {
						text-align: center;
						.setupButton {
							text-align: center;
							color: $BLUE1;
							cursor:pointer;
							.setupButtonIcon {
								font-size:80px;
							}
							.setupButtonLabel {
								font-size:40px;
							}
						}
					}
					.setupPaneFooter {
						position: absolute;
						bottom: 0;
						width:100%;
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
					}
				}
			}
		}
	}
}