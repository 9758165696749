@import 'style/_base';

.purchase {
    margin: 40px;
    .setupInstructions {
        font-size: 24px;
        margin: 10px 50px;
        text-align:center;
        ul {
            width: initial;
        }
    }
    .setupPane {
        
        .setupPaneTitle {
            text-align:center;
            font-size: 32px;
            margin: 20px 0; 
        }
        .bigIcon {
            font-size: 100px;
            color: $CORAL1;
            display: flex;
            justify-content: center;
        }
        .setupPaneList {
            text-align:center;
            overflow: auto;
            
            .setupPaneListTitle {
                font-size: 28px;
            }
            ul {
                width: initial;
                margin-bottom: 10px;
                list-style: none;
                li {
                    font-size: 20px;
                }
            }
            .setupPaneFootnote {
                font-size: 14px;
            }
        }
        .setupPaneButtons {
            text-align: center;
            .setupButton {
                text-align: center;
                color: $BLUE1;
                cursor:pointer;
                .setupButtonIcon {
                    font-size:80px;
                }
                .setupButtonLabel {
                    font-size:40px;
                }
            }
        }
        .setupPaneFooter {
            
            
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    .numberSearchResults {
        clear: left;
        width: 300px;
        border: 1px solid #a6a6a6;
        font-size: 12px;
        height:250px;
        overflow-y: scroll;
        margin: auto;
        ul {
            list-style:none;
            margin: 0;
            padding: 0;
            li {
                background-color: white;
                padding: 5px 10px;
                margin: 0;
                text-align: center;
                font-size:16px;
                cursor:pointer;
                &:nth-child(odd) {
                    background-color: #E6E6E6;
                }
                &:hover {
                    background-color: $BLUE2;
                    color: white;
                }
            }
        }
    }
}