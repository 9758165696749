@import 'style/_base';

.ivrWeekdays {
	border: 1px solid $DARKGRAY;
	height: 49px;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	div {
		display: flex;
		flex-flow: row nowrap;;
		flex-grow: 1;
		justify-content: center;
		padding: 15px 0;
		border-right: 1px solid $DARKGRAY;
		background-color: $LIGHTGRAY;
		&:last-child {
			border-right: none;
		}
		&:hover, &.selected {
			background-color: $MIDGRAY;
		}
	}
}