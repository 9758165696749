

.addDayButtonContainer {

}
.eventSchedule {
    height: 200px;
    border: 1px solid black;
    margin: 2px;
    overflow: auto;
    .day {
        padding: 2px;
        font-weight: bold;
        background-color: #ddd;
        border-bottom: 1px solid black;
    }
    .item {
        padding: 2px;
        border-bottom: 1px solid black;
    }
    .actionButtons {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        float: right;
        button {
            background: none;
            color: #444;
            padding: 5px;
            margin: 0 2px;
            height: 22px;
            border-radius: 0;
            border: none;
            min-height:unset;
            &:hover {
                background-color:#444;
                color: white;
            }
        }
    }
}