@import 'style/_base';

.schedules-container {
    display: flex;
    flex-flow: column nowrap;
    .schedules-header {
        display: flex;
        padding: 10px 0;
        justify-content: flex-end;
        font-size: 24px;
        .schedule-chooser {
            display: flex;
            select {
                border: none;
                background: none;
                box-shadow: none;
                text-decoration: underline;
                font-size: 20px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .no-schedule-view {
        display: flex;
        justify-content: center;
    }
    .schedule-view {
        display: flex;
        flex-flow: column;
        .schedule-view-header {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $DARKGRAY;
            padding: 10px 0;
            h4 {
                font-weight: 400;
                margin: 0;
            }
        }
        .shifts-container {
            display: flex;
            flex-flow: row nowrap;
            border: 1px solid $DARKGRAY;
            .left-panel {
                display: flex;
                flex-flow: column nowrap;
                width: 20%;
            }
            .right-panel {
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                overflow: auto;
                .row {
                    display: flex;
                    border-bottom: 1px solid $DARKGRAY;
                    width: 1600px;
                    &.top {
                        background-color: $LIGHTGRAY;
                        font-weight: bold;
                        .row-title {
                            justify-content: center;
                        }
                    }
                    .row-title {
                        display: flex;
                        width: 400px;
                        position: sticky;
                        left: 0;
                        border-right: 1px solid $DARKGRAY;
                        background: white;
                        justify-content: space-between;
                        .row-label {
                            display: flex;
                            align-items: center;
                            max-width: 350px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin: 0 5px;
                            .worker-select {
                                margin-left: 5px;
                                select {
                                    border: none;
                                    background: none;
                                    box-shadow: none;
                                    text-decoration: underline;
                                    &:focus {
                                        outline: none;
                                    }
                                    &:hover {
                                        border-width: 0;
                                        border-color: $BLUE2;
                                        margin: 3px;
                                    }
                                }
                            }
                        }
                        .row-date {
                            display: flex;
                            background-color: $MIDGRAY;
                            width: 2000px;
                            font-weight: 500;
                            justify-content: center;
                        }
                        .row-actions {
                            display: none;
                            align-items: center;
                            svg {
                                padding: 0 3px;
                            }
                        }
                    }
                    .hours {
                        display: flex;
                        .hour {
                            display: flex;
                            width: 49px;
                            justify-content: center;
                            border-right: 1px solid $DARKGRAY;
                        }
                    }
                    .shift {
                        height: 100%;
                        background-color: gray;
                        cursor: pointer;
                    }
                    &:hover {
                        background-color: $BLUE2;
                        .row-title .row-actions {
                            display: flex;
                        }
                        &:first-child {
                            background-color: white;
                        }
                    }
                    .date-container {
                        background-color: $MIDGRAY;
                        width: 100%;
                    }
                }
                .no-shifts {
                    display: flex;
                    justify-content:center;
                    height:100px;
                    h5 {
                        font-weight: 400;
                    }
                }
            }
        }
        .schedule-view-footer {
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
        }
    }
}

.schedule-tiles {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .schedule-tile-outer {
        display: flex;
        flex-flow: column;
        border: 3px solid $CORAL1;
        border-radius: 10px;
        margin: 10px;
        cursor: pointer;
        .schedule-tile-inner {
            display: flex;
            flex-flow: column;
            margin: 10px;
            width: 150px;
            align-items: center;
            .schedule-icon {
                color: $DARKGRAY;
                font-size: 48px;
            }
            .schedule-name {
                color: $CORAL1;
                font-weight: bold;
                text-align: center;
            }
            .schedule-shift-count span {
                font-weight:600;
            }
        }
        &:hover {
            background-color: $CORAL3;

        }
        &.selected {
            background-color: $CORAL2;
            .schedule-tile-inner {
                .schedule-name {
                    color: $WHITE;
                }
            }
        }
    }
}