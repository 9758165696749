
.ballotsOuter {
    background-color: white;
	height: 11in;
	width: 8.5in;
	margin-bottom: 1in;
    .ballotsInner {
        //padding: .5in .25in;
        display: flex;
        flex-flow: row wrap;
        .ballot {
            width: 8.5in;
            height: 5.5in;
            padding: .25in;
            display: flex;
            flex-flow: row nowrap;
            border-bottom: 1px dashed;
            .ballotDetails {
                text-align: center;
                width: 4.5in;
                h1, h5 {
                    margin: 4px 0;
                }
            }
            .ballotNotes {
                text-align: center;
            }
        }
    }
}