
@import 'style/_base';

$INBOUND: #eaebee;
$OUTBOUND: #ff560e;

.messagesContainerOuter {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    margin: 20px 40px;
    position: absolute;
    right: 0;
    left: 269px;
    top: 112px;
    bottom: 50px;
    .messagesContainerInner {
        display: flex;
        flex-flow: row nowrap;
        border: 1px solid $BLUE2;
        overflow-x: auto;
        min-height: 200px;
        .messagesButtonsOuter {
            display: flex;
            flex-flow: column;
            border-right: 1px solid $BLUE2;
            .messagesButtonContainer {
                display: flex;
                flex-flow: row;
                width: 50px;
                height: 50px;
                justify-content: center;
                align-items: center;
                button {
                    color: $DARKGRAY;
                    background: none;
                    border: none;
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    width: 30px;
                    &:hover {
                        background: $CORAL2;
                        color: $CORAL1;
                    }
                }
            }
        }
        .messagesCurrentAppContainer {
            display: flex;
            flex-flow: column;
            flex: 1;
            .messagesActionsContainer {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                width: 100%;
                height: 31px;
                padding-bottom: 10px;
                border-bottom: 1px solid $BLUE2;
                .messagesActions {
                    display: flex;
                    flex-flow: row nowrap;
                }
            }
            .messagesApp {
                display: flex;
                align-items: stretch;
                height: 100%;
                .messagesListContainer {
                    display: flex;
                    flex-flow: column;
                    width: 250px;
                    border-right: 1px solid $BLUE2;
                    
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        li {
                            display: flex;
                            height: 36px;
                            padding: 4px;
                            border-bottom: 1px solid $BLUE2;
                            justify-content: flex-start;
                            .memberInfoContainer {
                                display: flex;
                                flex-flow: column nowrap;
                                flex: 1;
                                font-size: 14px;
                                padding-left: 4px;
                                .firstRow {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    .memberName {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-content: center;
                                        font-weight: bold;
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                    .messageTime {
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                }
                                .secondRow {
                                    display: flex;
                                    max-width: 208px;
                                    .messageText {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        span {
                                            font-size: 18px!important;
                                            svg {
                                                font-size: 18px!important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .memberMessagesContainer {
                    display: flex;
                    flex-flow: column;
                    width: 350px;
                    border-right: 1px solid $BLUE2;
                    .memberMessagesHeader {
                        display: flex;
                        flex-flow: row nowrap;
                        border-bottom: 1px solid $BLUE2;
                        font-size: 12px;
                        padding: 5px;
                        justify-content: space-between;
                        .memberMessagesClose {
                            cursor: pointer;
                        }
                    }
                    ul {
                        display: flex;
                        flex: 1;
                        flex-flow: column;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        li {
                            display: flex;
                            flex-flow: row;
                            justify-content: flex-start;
                            .communicationBubbleOuter {
                                display: flex;
                                flex-flow: row;
                                max-width: 250px;
                                padding: 4px;
                                font-size: 12px;
                                .communicationBubbleInner {
                                    display: flex;
                                    flex-flow: column;
                                    .communicationSender {
                                        font-weight: bold;
                                        padding-right: 4px;
                                    }
                                    .communication {
                                        display: flex;
                                        .communicationContents {
                                            display: flex;
                                            background-color: $INBOUND;
                                            padding: 4px;
                                            margin: 4px;
                                            border-radius: 0 5px 5px 5px;
                                            img {
                                                width: 200px;
                                            }
                                        }
                                        .communicationMore {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            &.outbound {
                                justify-content: flex-end;
                                .communicationBubbleOuter {
                                    text-align: right;
                                    .communicationBubbleInner {
                                        .communicationSender {
                                            padding-left: 4px;
                                        }
                                        .communication {
                                            .communicationContents {
                                                background-color: $OUTBOUND;
                                                border-radius: 5px 0 5px 5px;
                                                color: white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .memberMessagesFooter {
                        display: flex;
                        flex-flow: row nowrap;
                        border-top: 1px solid $BLUE2;
                        font-size: 12px;
                        padding: 5px;
                        .communicationActions {
                            display: flex;
                            flex-flow: row nowrap;
                            border: 1px solid $BLUE2;
                            border-radius: 5px;
                            height: 30px;
                            width: 100%;
                            align-items: center;
                            justify-content: space-around;
                            .attachmentButton, .sendButton {
                                display: flex;
                                width: 30px;
                                justify-content: center;
                                font-size: 18px;
                                cursor: pointer;
                                color: $DARKGRAY;
                                &:hover {
                                    color: $CORAL1
                                }
                            }
                            .input {
                                width: 250px;
                                border-right: 1px solid $BLUE2;
                                border-left: 1px solid $BLUE2;
                                input {
                                    width: 236px;
                                    margin: 0;
                                    //padding: 0;
                                    border: none;
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.memberIconContainer {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 12px;
    background-color: black;
    color: white;
}

.sidePanelForm {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

.findContactResults {
    border: 1px solid $BLUE2;
    height: 250px;
    overflow-y: auto;
    ul {
        list-style: none;
        margin: 0;
        padding: 4px;
        li {
            padding: 4px;
            cursor: pointer;
            &:hover {
                font-weight: bold;
                color: $CORAL1;
            }
        }
    }
}