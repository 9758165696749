
.memberCardsOuter {
	background-color: white;
	height: 11in;
	width: 8.5in;
	margin-bottom: 5px;
}
.memberCardsInner {
	padding: 1in .75in;
	.memberCardOuter {
		float: left;
		height: 2.25in;
		width: 3.5in;
	}
    .logoContainer {
        display: flex;
        .clubLogo {
            margin: 0 auto;
            height: 74px;
	    }
    }
	.memberCardInner {
		.content {
			font-size: 20px;
			font-weight: bold;
			margin: 5px 10px;
			text-align:center;
		}
		.footer {
			font-size: 12px;
			margin: 5px 10px;
		}
	}
}