@import 'style/_base';

.channelChatBubble, .channelFileBubble {
	padding: 6px;
	display: flex;
    flex-flow: row nowrap;
	//height: 48px;
	white-space: nowrap;
	width: 100%;
	.avatar {
        display: flex;
        flex-flow: row nowrap;
		color: white;
		text-align:center;
		margin-top: 5px;
		width: 40px;
		height: 40px;
		border-radius: 5px;
		background-color: $BLUE1;
		.char {
			padding-top:10px;
            width: 40px;
		    height: 40px;
		}
		img {
			width: 40px;
			height: 40px;
			border-radius: 5px;
		}
	}
	.icon {
		color: #3594dd;
		text-align:center;
		margin-top: 5px;
		width: 40px;
		height: 40px;
		//float: left;
		img {
			width: 40px;
			height: 40px;
			border-radius: 5px;
		}
	}
	.chatInfo {
        display:flex;
        flex-flow: column nowrap;
		padding-top:4px;
		font-size: 12px;
		white-space:normal;
		padding-right: 40px;
		.username {
			display: flex;
			padding-left:5px;
		}
		.time {
			display: flex;
			padding-left: 4px;
			color: $MIDGRAY;
		}
		.messageContent {
			display: flex;
			font-size: 14px;
			padding-left: 5px;
			span {
				&.mention {
					color: #de5824;
					font-size: 11px;
					border: 1px solid #de5824;
					border-radius: 10px;
					padding: 3px;
					background-color: #f0cdbe;
					cursor: pointer;
				}
				&.channel {
					padding: 0;
					color: #78A300;
					border-bottom: none;
					cursor: unset;
				}
			}
		}
		.messageMedia {
			display: flex;
			padding-left: 5px;
			.filedownload {
				font-size: 24px;
				width: 100px;
				text-align: center;
				background: gray;
				border-radius: 5px;
				color: white;
				height: 50px;
				cursor: pointer;
			}
		}
		.mediaName {
			display: flex;
			padding-left: 5px;
			font-size: 14px;
		}
	}
	.actions {
		cursor: pointer;
		position: relative;
		right: 21px;
		float: right;
		display: none;
		.action {
			border: 1px solid $DARKGRAY;
			background-color: white;
			border-radius: 5px;
			margin: 0 4px;
			.fa {
				padding: 5px;
			}
		}
	}
	&:hover {
		.actions {
			display: block;
		}
	}
}
.channelFileBubble {
	&:hover {
		background-color: $LIGHTGRAY;
	}
}