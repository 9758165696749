@import 'style/_base';

.video-container {
    display: flex;
    flex-flow: row nowrap;
    max-width: 1070px;
    background: darkgray;
    margin: auto;
    min-height: 900px; 
    .video-track {
        display: flex;
        flex-flow: row nowrap;
        border: 1px solid $DARKGRAY;
        width: 320px;
        height: 240px;
        background-color: $MIDGRAY;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        .video-area {
            position: relative;
            width: 320px;
            height: 240px;
        }
        .round-button-area {
            position: absolute;
            display: flex;
            flex-flow: row nowrap;
            justify-items: center;
            justify-content: center;
            height: 50px;
            width: 320px;
            bottom: 0;
            button {
                display: flex;
                flex-flow: row nowrap;
                //background-color: $LIGHTGRAY;
                width: 35px;
                height: 35px;
                padding: 8px 7px;
                border-radius: 20px;
                font-size: 16px;
                margin: 5px;
                text-align: center;
            }
        }
    }
    .room {
        display: flex;
        flex-flow: column nowrap;
        border-left: 2px solid $DARKGRAY;
        flex-grow: 1;
    }
}