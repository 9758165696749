@import 'style/_base';

.transactions-outer {
    display: flex;
    flex-flow: column nowrap;
    margin: 30px;
    h3 {
        font-weight: 400;
        margin: 5px 0;
    }
    .snapshot-area {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        .snapshot {
            border-radius:5px;
            margin: 20px 4px;
            background-color: $LIGHTGRAY;
            display: flex;
            flex-flow: row nowrap;
            flex-grow:1;
            align-items: center;
            justify-content: space-around;
            height: 100px;
            .summary {
                font-weight: bold;
                font-size: 24px;
            }
            .summary-month {
                font-weight: bold;
            }
            .total {
                padding:5px 10px;
                display: flex;
                flex-flow: row wrap;
                .total-icon {
                    display: flex;
                    background-color: $CORAL1;
                    color: $WHITE;
                    font-size: 40px;
                    padding: 10px;
                    width: 45px;
                    height: 45px;
                    border-radius: 40px;
                    justify-content: center;
                    align-items: center;
                }
                .total-label-and-ammount {
                    display: flex;
                    flex-flow: column nowrap;
                    align-self: center;
                    margin-left: 5px;
                    .total-label {
                        display: flex;
                        justify-content: center;
                    }
                    .total-ammount {
                        display: flex;
                        color: $CORAL1;
                        font-size: 24px;
                        font-weight: bold ;
                    }
                }
            }
            .views {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                width: 100%;
                .view-selector {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    padding: 5px 10px;
                    cursor: pointer;
                    .view-button {
                        display: flex;
                        background-color: $BLUE3;
                        color: $BLUE2;
                        font-size: 40px;
                        padding: 10px;
                        width: 45px;
                        height: 45px;
                        border-radius: 40px;
                        justify-content: center;
                        align-items: center;
                    }
                    .view-label {
                        color: $BLUE2;
                    }
                    &.selected {
                        .view-label {
                            color: $BLACK;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .transactions-table {
        display: flex;
        flex-flow: column nowrap;
        h4 {
            font-weight: 400;
            margin: 5px 0;
        }
        .table-area {
            margin: 0;
        }
    }
}

div.status {
    padding: 0 10px !important;
    width: unset!important;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: lightgray;
    &.succeeded {
        border: 1px solid green;
        background-color: lightgreen;
    }
    &.requires_action, &.refunded {
        border: 1px solid red;
        background-color: lightpink;
    }
}

@media (max-width:1020px) {
    .transactions-outer {
        flex-flow: column nowrap;
        .snapshot-area {
            width: unset;
        }
        .transactions-table {
            width: unset;
        }
    }
}