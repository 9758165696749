@import './_base';

.account-messages {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(0,0,0,0.8);
    display: flex;
    .inner-area {
        width: 500px;
        height: 300px;
        background: white;
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        .inner-title-area {
            padding: 10px;
            h1 {
                margin: 0;
            }
            border-bottom: 1px solid $DARKGRAY;
        }
        .inner-center-area {
            padding: 10px;
            .message-list-area {
                border: 1px solid $DARKGRAY;
                height: 100px;
                overflow: auto;
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        border-bottom: 1px solid $DARKGRAY;
                        padding: 5px;
                        display: flex;
                        flex-flow: column nowrap;
                        .button-area {
                            display: flex;
                            justify-content: flex-end;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
        .inner-button-area {
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid $DARKGRAY;
        }
    }
}