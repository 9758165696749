.ballot_list {
	list-style: none;
	margin: 0px;
	padding: 0px;
}
.ballot_list li {
	padding-top: 3px;
}
.toolbar {
	height:45px;
	background-color:#d9d9d9;
	opacity:0.80;
	bottom:0;
	right:0;
	position:fixed;
}
.toolbar .contents {
	margin: 5px;
	text-align:right;
}