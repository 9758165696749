@import 'style/_base';

.ivr {
    textarea {
        width: 480px;
        height: 100px;
    }
    hr {
        margin: 20px 0;
    }
    .patternTableHeading {
        div {
            float: left;
            width: 50%;
            text-align:center;
            font-weight: bold;
        }
        &.one div {
            width: 100%;
        }
    }
    .patterns {
        border: 1px solid $DARKGRAY;
        height: 200px;
        overflow-x: auto;
        margin: auto;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                border-bottom: 1px solid $DARKGRAY;
                height: 20px;
                margin: 0;
                padding: 4px;
                cursor: pointer;
                &:nth-child(even) {
                    background-color: $LIGHTGRAY;
                }
                div {
                    float: left;
                    width: 50%;
                    text-align:center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                &:hover {
                    background-color: $BLUE2;
                } 
            }
        }
        &.one ul li div {
            width: 100%;
        }
    }

    .patternSettings {
        overflow: hidden;
        //height: 0;
        margin: auto;
        border-right:1px solid $DARKGRAY;
        border-left:1px solid $DARKGRAY;
        border-bottom:1px solid $DARKGRAY;
        ul {
            list-style: none;
            margin: 0;
            padding: 10px;
        }
    }
}