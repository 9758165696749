@import 'style/_base';

.radioGroup {
	ul {
		width: 100%;
		margin: 0;
		padding: 10px 0;
        list-style: none;
		li {
			padding: 5px 0;
			font-size: 18px;
			height: 20px;
            display: flex;
			.fas {
				width: 20px;
				height: 20px;
				float: left;
				&.fa-circle {
					color: $MIDGRAY;
				}
				&.fa-dot-circle {
					color: $BLUE1;
				}
			}
			.radioLabel {
				float: left;
				margin-left: 10px;
				&.selected {
					color: blue;
				}
			}
		}
	}
}