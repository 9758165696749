@import 'style/_base';

.options-container {
    display: flex;
    margin-bottom: 20px;
    .options-outer {
        display: flex;
        flex-flow: column nowrap;
        .options-inner {
            display: flex;
            flex-flow: column nowrap;
            section {
                display: flex;
                flex-flow: column nowrap;
                margin: 20px 0;
                padding: 0 20px;
                h4 {
                    font-weight: normal;
                    margin: 0;
                }
                label {
                    margin-top: 20px;
                }
                input[type=text], input[type=email], textarea {
                    width: 300px;
                }
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}