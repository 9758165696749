@import 'style/_base';

.group-chat-outer {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    height: 100%;
    .group-chat-inner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 100%;
        .left-pane-view {
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            width: 30%;
            overflow: hidden;
            .top-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                border-bottom: 1px solid $DARKGRAY;
                border-right: 1px solid $DARKGRAY;
                height: 50px;
                white-space:nowrap;
                overflow:hidden;
                .top-container-side {
                    display: flex;
                }
                .left-pane-title {
                    display: flex;
                    text-transform: uppercase;
                    margin: 16px 0 0 10px;
                }
                .status-toggle {
                    display: flex;
                    padding:10px 10px;
                    select {
                        width: 120px;
                    }
                }
                .transparentButton {
                    display: flex;
                    padding: 14px 5px 0 0;
                    cursor: pointer;
                }
            }
            .center-container {
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                justify-content: space-evenly;
                border-right: 1px solid $DARKGRAY;
                .channelListContainer, .privateChannelListContainer {
                    display: flex;
                    flex-flow: column nowrap;
                    overflow-y: auto;
                    flex-grow: 1;
                    .channelListHeader, .privateChannelListHeader {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        color: white;
                        background-color: $NAVY1;
                        border-bottom: 1px solid $DARKGRAY;
                        border-top: 1px solid $DARKGRAY;
                        height: 30px;
                    }
                    .channelList, .privateChannelList {
                        ul {
                            list-style: none;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            li {
                                margin: 0;
                                &.selected, &:hover {
                                    background-color: $BLUE2;
                                    //color: white;
                                }
                                &:last-child {
                                    .channel {
                                        border-bottom: none;
                                    }
                                }
                                &.chatInfo {
                                    text-align:center;
                                }
                            }
                        }
                    }
                }
            }
            .bottom-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                border-top: 1px solid $DARKGRAY;
                border-right: 1px solid $DARKGRAY;
                height: 50px;
            }
        }   
    }
}


.chat-room-item {
	display: flex;
    flex-flow: row nowrap;
    padding: 6px;
	height: 37px;
	border-bottom: 1px solid $DARKGRAY;
	cursor: pointer;
    &.inactive {
        background-color: $MIDGRAY;
    }
	.status {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
		.fa {
			&.fa-circle {
				color: $BLUE1;
			}
			&.fa-circle-o {
				color: $MIDGRAY;
			}
			&.inameeting {
				color: $CORAL1;
			}
			&.donotdisturb {
				color: red;
			}
		}
	}
	.avatar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
		color: white;
		margin-top: 5px;
		margin-left: 5px;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		background-color: $BLUE1;
		&.group {
			background-color: $BLUE2;
		}
		.char {
			padding-top:6px;
		}
		img {
			width: 30px;
			height: 30px;
			border-radius: 5px;
		}
	}
	.count {
		color: white;
		background-color: red;
		border-radius: 5px;
		font-size: 12px;
		text-align: center;
		padding: 0 4px;
	}
	.name {
        display: flex;
        flex-grow: 1;
		padding-top: 10px;
		margin: 0 5px;
		overflow: hidden;
		height: 20px;
		text-overflow: ellipsis;
		white-space: nowrap;
		&.attention {
			color: blue;
			text-decoration: underline
		}
	}
	.notification {
		position: relative;
		bottom: 3px;
		left: -42px;
		font-size: 16px;
		color: green;
		display: none;
	}
	.editBtn {
		display:none;
        float: right;
		position: relative;
		bottom: -7px;
		font-size: 20px;
	}
	.activity {
		font-size: 20px;
    }
	&:hover {
		.editBtn {
			display: flex;
		}
	}
}

.rightPaneView {
    width: 70%;
    display: flex;
    flex-flow: column nowrap;
    .heading {
        display: flex;
        border-bottom: 1px solid $DARKGRAY;
        height: 50px;
        .headingButton {
            text-transform: uppercase;
            color: $BLUE1;
            cursor:pointer;
            margin: 16px 13px 0 13px;
            font-size:14px;
        }
        .headingLeft {
            float: left;
            width:33%;
            height: 50px;
        }
        .headingCenter {
            float: left;
            width: 33%;
            height: 20px;
            text-align:center;
            font-weight: bold;
            margin-top: 16px;
            overflow:hidden;
        }
        .headingRight {
            float: right;
        }
    }
    .rightPaneContent {
        display: flex;
        flex-flow: column nowrap;
        overflow-y: scroll;
        flex-grow: 1;
        .chat-list-item {
            display: flex;
            flex-flow: row nowrap;

        }
    }
    .chat-actions {
        display: flex;
        flex-flow: column nowrap;
        height: 100px;
        border-top: 1px solid $DARKGRAY;
        .msg-action {
            display: flex;
            flex-flow: row nowrap;
            .editable-div {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 1;
                border: 1px solid $DARKGRAY;
                padding: 5px;
                margin: 5px;
            }
            .button-holder {
                padding-top: 7px;
            }
        }
    }
}