@import './base';

.ticket-group {
    display: flex;
    flex-flow: column nowrap;
    background-color: white;
	//height: 11in;
	width: 8.5in;
	margin: 50px;
    .ticket-group-heading {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        border: 1px solid $DARKGRAY;
        padding: 5px;
        font-weight: bold;
        background-color: black;
		color: white;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            flex-flow: column nowrap;
            border: 1px solid $DARKGRAY;
            padding: 5px;
            .ticker-purchaser-info {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
            .ticket-value {
                display: flex;
                flex-flow: row nowrap;
                margin-left: 20px;
            }
        }
    }
}