
.campaign-logs {
    margin: 50px;
    padding: 20px;
    border: 1px solid gray;
    background-color: lightgray;
    .no-logs {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }
    .campaign-logged-instance {
        .log-title {
            font-size: 18px;
            font-weight: 600;
        }
        .log-data {
            
            pre {
                background-color: black;
                color: white;
                padding: 20px;
                overflow: auto;
                font-size: 14px;
            }
        }
    }
}